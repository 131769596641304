<div class="relative my-7 text-center md:mb-9">
    <span class="absolute inset-x-0 top-1/2 h-px w-full -translate-y-1/2 bg-white-light dark:bg-white-dark"></span>
    <span class="relative bg-white px-2 font-bold uppercase text-white-dark dark:bg-dark dark:text-white-light">{{ 'LOGIN.OR' | translate }}</span>
</div>
<div class="mb-10 md:mb-[60px]">
    <ul class="flex justify-center gap-3.5 text-white">
        <li>
            <a target="_blank"
                href="https://www.instagram.com/pitzon.io/"
                class="inline-flex h-8 w-8 items-center justify-center rounded-full p-0 transition hover:scale-110 animate__animated animate__delay-1s animate__zoomInUp"
                style="background: linear-gradient(135deg, #ff5801 0%, #00022e 120%)"
                (click)="networks()"
            >
                <icon-instagram />
            </a>
        </li>
        <li>
            <a target="_blank"
                href="https://www.facebook.com/Pitzon.io?locale=fo_FO"
                class="inline-flex h-8 w-8 items-center justify-center rounded-full p-0 transition hover:scale-110 animate__animated animate__delay-1s animate__zoomInUp"
                style="background: linear-gradient(135deg, #ff5801 0%, #00022e 120%)" 
                (click)="networks()"
                >
                <icon-facebook-circle />
                
            </a>
        </li>
        <li>
            <a target="_blank"
            href="https://x.com/Juancamoller"
            class="inline-flex h-8 w-8 items-center justify-center rounded-full p-0 transition hover:scale-110 animate__animated animate__delay-1s animate__zoomInUp"
            style="background: linear-gradient(135deg, #ff5801 0%, #00022e 120%)"
            (click)="networks()"
            >
            <icon-x/>
        </a>
        </li>
        <li>
            <a target="_blank"
            href="https://www.linkedin.com/company/pitzon-io/"
            class="inline-flex h-8 w-8 items-center justify-center rounded-full p-0 transition hover:scale-110 animate__animated animate__delay-1s animate__zoomInUp"
            style="background: linear-gradient(135deg, #ff5801 0%, #00022e 120%)"
            (click)="networks()"
            >
            <icon-linkedin />
        </a>
        </li>
    </ul>
</div>