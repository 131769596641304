<div>
    <header></header>
        
        <ul class="flex space-x-2 rtl:space-x-reverse z-50">
            <li>
                <a href="javascript:;" (click)="goBack()" class="text-black dark:text-white  cursor-pointer hover:underline animate__animated animate__bounceInLeft animate__delay-1s">Inicio</a>
            </li>
            <li class="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-white">
                <span class="text-primary z-50 animate__animated animate__bounceInRight animate__delay-1s">Perfil</span>
            </li>
        </ul>
    <div class="bg-center flex items-center justify-center animate__animated animate__fadeIn animate__delay-1s">
        <img *ngIf="isDarkMode === false "src="../../../../assets/images/logo-22.png" alt="img" class=" w-36 flex justify-center items-center animate__animated animate__fadeIn animate__delay-1s">
        <img *ngIf="isDarkMode === true "src="../../../../assets/images/logo-23.png" alt="img" class=" w-36 flex justify-center items-center animate__animated animate__fadeIn animate__delay-1s">
        </div>
    <div class="pt-5">
        <div class="mb-5 grid grid-cols-1 gap-5 lg:grid-cols-3 xl:grid-cols-4">
            <div class="panel">
                <div class="mb-5 flex items-center justify-between">
                    <h5 class="text-lg font-semibold dark:text-white-light animate__animated animate__jackInTheBox animate__delay-1s">Perfil</h5>
                    <a (click)="goToEditProfile()" class="btn btn-primary rounded-full p-2 cursor-pointer ltr:ml-auto rtl:mr-auto animate-pulse">
                        <icon-pencil-paper />
                    </a>
                </div>
                <div class="mb-5">
                    <div class="flex flex-col items-center justify-center">
                        <img src="../../../../assets/images/juan2.png" alt="" class="mb-5 h-24 w-24 rounded-full object-cover animate__animated animate__jackInTheBox animate__delay-1s" />
                        <p class="text-xl font-semibold text-primary">Juan Mecanico</p>
                    </div>
                    <ul class="m-auto mt-5 flex max-w-[160px] flex-col space-y-4 font-semibold text-white-dark">
                        <li class="flex items-center gap-2">
                            <icon-coffee class="shrink-0" />
                            Mecanico       
                        </li>
                        <li class="flex items-center gap-2">
                            <icon-calendar class="shrink-0" />
                            Jan 20, 1989
                        </li>
                        <li class="flex items-center gap-2">
                            <icon-map-pin class="shrink-0" />
                            Colombia, Pereira
                        </li>
                        <li>
                            <a href="javascript:;" class="flex items-center gap-2">
                                <icon-mail class="h-5 w-5 shrink-0" />
                                <span class="truncate text-primary">{{"juan@gmail.com"}}</span></a
                            >
                        </li>
                        <li class="flex items-center gap-2">
                            <icon-phone />
                            <span class="whitespace-nowrap" dir="ltr">(+57) 555-12121</span>
                        </li>
                    </ul>
                    <ul class="mt-7 flex items-center justify-center gap-2">
                        <li>
                            <a class="btn btn-info flex h-10 w-10 items-center justify-center rounded-full p-0" href="javascript:;">
                                <icon-twitter class="h-5 w-5" />
                            </a>
                        </li>
                        <li>
                            <a class="btn btn-danger flex h-10 w-10 items-center justify-center rounded-full p-0" href="javascript:;">
                                <icon-facebook />
                            </a>
                        </li>
                        <li>
                            <a class="btn btn-dark flex h-10 w-10 items-center justify-center rounded-full p-0" href="javascript:;">
                                <icon-instagram />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="panel lg:col-span-2 xl:col-span-3">
                <div class="mb-5">
                    <h5 class="text-lg font-semibold dark:text-white-light">Historial del vehiculo</h5>
                </div>
                <div class="mb-5">
                    <div class="table-responsive font-semibold text-[#515365] dark:text-white-light">
                        <table class="whitespace-nowrap">
                            <thead>
                                <tr class="animate__animated animate__zoomIn animate__delay-1s">
                                    <th>Servicios</th>
                                    <th>Progreso</th>
                                    <th>Porcentaje</th>
                                    <th class="text-center">Tiempo</th>
                                </tr>
                            </thead>
                            <tbody class="dark:text-white-dark">
                                <tr class="animate__animated animate__zoomInLeft animate__delay-1s">
                                    <td>Cambio de aceite</td>
                                    <td>
                                        <div class="flex h-1.5 w-full rounded-full bg-[#ebedf2] dark:bg-dark/40">
                                            <div class="w-[29.56%] rounded-full bg-danger"></div>
                                        </div>
                                    </td>
                                    <td class="text-danger">29.56%</td>
                                    <td class="text-center">5 dias</td>
                                </tr>
                                <tr class="animate__animated animate__zoomInRight animate__delay-1s">
                                    <td>Revisión de neumáticos</td>
                                    <td>
                                        <div class="flex h-1.5 w-full rounded-full bg-[#ebedf2] dark:bg-dark/40">
                                            <div class="w-1/2 rounded-full bg-info"></div>
                                        </div>
                                    </td>
                                    <td class="text-success">50%</td>
                                    <td class="text-center">2 dias</td>
                                </tr>
                                <tr class="animate__animated animate__zoomInLeft animate__delay-1s">
                                    <td>Revision de liquidos</td>
                                    <td>
                                        <div class="flex h-1.5 w-full rounded-full bg-[#ebedf2] dark:bg-dark/40">
                                            <div class="w-[39%] rounded-full bg-warning"></div>
                                        </div>
                                    </td>
                                    <td class="text-danger">39%</td>
                                    <td class="text-center">3 dias</td>
                                </tr>
                                <tr class="animate__animated animate__zoomInRight animate__delay-1s">
                                    <td>Lavado y encerado</td>
                                    <td>
                                        <div class="flex h-1.5 w-full rounded-full bg-[#ebedf2] dark:bg-dark/40">
                                            <div class="w-[78.03%] rounded-full bg-secondary"></div>
                                        </div>
                                    </td>
                                    <td class="text-success">78.03%</td>
                                    <td class="text-center">1 hora</td>
                                </tr>

                                <tr class="animate__animated animate__zoomInLeft animate__delay-1s">
                                    <td>Cambio de pastillas</td>
                                    <td>
                                        <div class="flex h-1.5 w-full rounded-full bg-[#ebedf2] dark:bg-dark/40">
                                            <div class="w-full rounded-full bg-success"></div>
                                        </div>
                                    </td>
                                    <td class="text-success">100%</td>
                                    <td class="text-center">Listo</td>
                                </tr>
                                <tr class="animate__animated animate__zoomInRight animate__delay-1s">
                                    <td>Diagnostico eletronico</td>
                                    <td>
                                        <div class="flex h-1.5 w-full rounded-full bg-[#ebedf2] dark:bg-dark/40">
                                            <div class="w-[19.15%] rounded-full bg-danger"></div>
                                        </div>
                                    </td>
                                    <td class="text-danger">19.15%</td>
                                    <td class="text-center">3 semanas</td>
                                </tr>
                                <tr class="animate__animated animate__zoomInLeft animate__delay-1s">
                                    <td>Ajuste de embrague</td>
                                    <td>
                                        <div class="flex h-1.5 w-full rounded-full bg-[#ebedf2] dark:bg-dark/40">
                                            <div class="w-[60.55%] rounded-full bg-primary"></div>
                                        </div>
                                    </td>
                                    <td class="text-success">60.55%</td>
                                    <td class="text-center">1 mes</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
            <div class="panel">
                <div class="mb-5">
                    <h5 class="text-lg font-semibold dark:text-white-light animate__animated animate__slideInDown animate__delay-1s">MULTAS</h5>
                </div>
                <div class="space-y-4">
                    <div class="rounded border border-[#ebedf2] dark:border-0 dark:bg-[#1b2e4b]">
                        <div class="flex items-center justify-between p-4 py-2">
                            <div
                                class="grid h-9 w-9 shrink-0 place-content-center rounded-md bg-secondary-light text-secondary dark:bg-secondary dark:text-secondary-light"
                            >
                                <icon-tether />
                            </div>
                            <div class="flex flex-auto items-start justify-between font-semibold ltr:ml-4 rtl:mr-4">
                                <h6 class="text-[13px] text-white-dark dark:text-white-dark">
                                    Asesinato <span class="block text-base text-[#515365] dark:text-white-light">$50.200.000</span>
                                </h6>
                                <p class="text-secondary ltr:ml-auto rtl:mr-auto">90%</p>
                            </div>
                        </div>
                    </div>
                    <div class="rounded border border-[#ebedf2] dark:border-0 dark:bg-[#1b2e4b]">
                        <div class="flex items-center justify-between p-4 py-2">
                            <div class="grid h-9 w-9 shrink-0 place-content-center rounded-md bg-info-light text-info dark:bg-info dark:text-info-light">
                                <icon-tag />
                            </div>
                            <div class="flex flex-auto items-start justify-between font-semibold ltr:ml-4 rtl:mr-4">
                                <h6 class="text-[13px] text-white-dark dark:text-white-dark">
                                    Infrasion de transito <span class="block text-base text-[#515365] dark:text-white-light">$1.000.000</span>
                                </h6>
                                <p class="text-info ltr:ml-auto rtl:mr-auto">65%</p>
                            </div>
                        </div>
                    </div>
                    <div class="rounded border border-[#ebedf2] dark:border-0 dark:bg-[#1b2e4b]">
                        <div class="flex items-center justify-between p-4 py-2">
                            <div
                                class="grid h-9 w-9 shrink-0 place-content-center rounded-md bg-warning-light text-warning dark:bg-warning dark:text-warning-light"
                            >
                                <icon-credit-card />
                            </div>
                            <div class="flex flex-auto items-start justify-between font-semibold ltr:ml-4 rtl:mr-4">
                                <h6 class="text-[13px] text-white-dark dark:text-white-dark">
                                    No portar documentos al dia <span class="block text-base text-[#515365] dark:text-white-light">$3.000.000</span>
                                </h6>
                                <p class="text-warning ltr:ml-auto rtl:mr-auto">80%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel">
                <div class="mb-10 flex items-center justify-between">
                    <h5 class="text-lg font-semibold dark:text-white-light">Licencia</h5>
                    <a href="javascript:;" class="btn btn-primary">Renovar ahora</a>
                </div>
                <div class="group">
                    <ul class="mb-7 list-inside list-disc space-y-2 font-semibold text-white-dark">
                        <li>A2</li>
                        <li>B1</li>
                        <li>C1</li>
                    </ul>
                    <div class="mb-4 flex items-center justify-between font-semibold">
                        <p class="flex items-center rounded-full bg-dark px-2 py-1 text-xs font-semibold text-white-light">
                            <icon-clock class="h-3 w-3 ltr:mr-1 rtl:ml-1" />
                            3 años
                        </p>
                        <p class="text-info">$25 / month</p>
                    </div>
                    <div class="mb-5 h-2.5 overflow-hidden rounded-full bg-dark-light p-0.5 dark:bg-dark-light/10">
                        <div class="relative h-full w-full rounded-full bg-gradient-to-r from-[#f67062] to-[#fc5296]" style="width: 65%"></div>
                    </div>
                </div>
            </div>
            <div class="panel">
                <div class="mb-5 flex items-center justify-between">
                    <h5 class="text-lg font-semibold dark:text-white-light">Documentos al dia</h5>
                </div>
                <div>
                    <div class="border-b border-[#ebedf2] dark:border-[#1b2e4b]">
                        <div class="flex items-center justify-between py-2">
                            <h6 class="font-semibold text-[#515365] dark:text-white-dark">
                                vence en marzo<span class="block text-white-dark dark:text-white-light">SOAT</span>
                            </h6>
                            <div class="flex items-start justify-between ltr:ml-auto rtl:mr-auto">
                                <!-- <p class="font-semibold text-primary">90%</p> -->
                                <div class="dropdown ltr:ml-4 rtl:mr-4 text-primary">
                                    <div hlMenu>
                                        <a href="javascript:;" hlMenuButton class="align-middle">
                                            <icon-horizontal-dots class="opacity-80 hover:opacity-100" />
                                        </a>
                                        <ul *hlMenuItems @toggleAnimation class="whitespace-nowrap ltr:right-0 rtl:left-0">
                                            <li>
                                                <a href="javascript:;" *hlMenuItem="let menuItem">Ver factura</a>
                                            </li>
                                            <li>
                                                <a href="javascript:;" *hlMenuItem="let menuItem">Descargar factura</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border-b border-[#ebedf2] dark:border-[#1b2e4b]">
                        <div class="flex items-center justify-between py-2">
                            <h6 class="font-semibold text-[#515365] dark:text-white-dark">
                                vence en octubre <span class="block text-white-dark dark:text-white-light">TECNOMECANICA</span>
                            </h6>
                            <div class="flex items-start justify-between ltr:ml-auto rtl:mr-auto">
                                <!-- <p class="font-semibold text-primary">20%</p> -->
                                <div class="dropdown ltr:ml-4 rtl:mr-4 text-primary">
                                    <div hlMenu>
                                        <a href="javascript:;" hlMenuButton class="align-middle">
                                            <icon-horizontal-dots class="opacity-80 hover:opacity-100" />
                                        </a>
                                        <ul *hlMenuItems @toggleAnimation class="whitespace-nowrap ltr:right-0 rtl:left-0">
                                            <li>
                                                <a href="javascript:;" *hlMenuItem="let menuItem">Ver factura</a>
                                            </li>
                                            <li>
                                                <a href="javascript:;" *hlMenuItem="let menuItem">Descargar factura</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="flex items-center justify-between py-2">
                            <h6 class="font-semibold text-[#515365] dark:text-white-dark">
                                <icon-star /><span class="block text-white-dark dark:text-white-light">.....</span>
                            </h6>
                            <div class="flex items-start justify-between ltr:ml-auto rtl:mr-auto">
                                <!-- <p class="font-semibold">90%</p> -->
                                <div class="dropdown ltr:ml-4 rtl:mr-4">
                                    <div hlMenu>
                                        <a href="javascript:;" hlMenuButton class="align-middle">
                                            <!-- <icon-horizontal-dots class="opacity-80 hover:opacity-100" /> -->
                                        </a>
                                        <ul *hlMenuItems @toggleAnimation class="whitespace-nowrap ltr:right-0 rtl:left-0">
                                            <li>
                                                <a href="javascript:;" *hlMenuItem="let menuItem">View Invoice</a>
                                            </li>
                                            <li>
                                                <a href="javascript:;" *hlMenuItem="let menuItem">Download Invoice</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel">
                <div class="mb-5 flex items-center justify-between">
                    <h5 class="text-lg font-semibold dark:text-white-light">Vehiculo</h5>
                </div>
                <div>
                    <div class="border-b border-[#ebedf2] dark:border-[#1b2e4b]">
                        <div class="flex items-center justify-between py-2">
                            <div class="flex-none">
                                <img src="/assets/images/card-americanexpress.svg" alt="" />
                            </div>
                            <div class="flex flex-auto items-center justify-between ltr:ml-4 rtl:mr-4">
                                <h6 class="font-semibold text-[#515365] dark:text-white-dark">
                                    chevrolet spark <span class="block text-white-dark dark:text-white-light">Automovil 12/07/2013</span>
                                </h6>
                                <span class="badge bg-success ltr:ml-auto rtl:mr-auto">Color</span>
                            </div>
                        </div>
                    </div>
                    <div class="border-b border-[#ebedf2] dark:border-[#1b2e4b]">
                        <div class="flex items-center justify-between py-2">
                            <div class="flex-none">
                                <img src="/assets/images/card-mastercard.svg" alt="" />
                            </div>
                            <div class="flex flex-auto items-center justify-between ltr:ml-4 rtl:mr-4">
                                <h6 class="font-semibold text-[#515365] dark:text-white-dark">
                                    Dukati <span class="block text-white-dark dark:text-white-light">Motocicleta 03/05/2025</span>
                                </h6>
                                <span class="badge bg-info ltr:ml-auto rtl:mr-auto">Color</span>

                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="flex items-center justify-between py-2">
                            <div class="flex-none">
                                <img src="/assets/images/card-visa.svg" alt="" />
                            </div>
                            <div class="flex flex-auto items-center justify-between ltr:ml-4 rtl:mr-4">
                                <h6 class="font-semibold text-[#515365] dark:text-white-dark">
                                    BMW <span class="block text-white-dark dark:text-white-light">Royce 10/02/2024</span>
                                </h6>
                                <span class="badge bg-red-600 ltr:ml-auto rtl:mr-auto">Color</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
