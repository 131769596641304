import { Component } from '@angular/core';

@Component({
  selector: 'app-testimony',
  templateUrl: './testimony.component.html',
  styleUrl: './testimony.component.css',
})
export class TestimonyComponent {
  isPaused: boolean = false;
  testimony: any[] = [
    {
      img: '../../../../assets/images/testimony/testi1.jpg',
      titl: 'Juan Camilo Chaverra',
      subtitle:
        'En Pitzon encontré la solución perfecta para gestionar mi vehículo. El proceso es rápido y sencillo, con una atención al cliente excepcional. Me siento seguro sabiendo que tengo todo en regla y actualizado. ¡Recomendaría Pitzon a cualquiera que busque eficiencia!',
    },
    {
      img: '../../../../assets/images/testimony/test5.jpg',
      titl: 'Luis Estrella',
      subtitle:
        'Pitzon me ha facilitado mucho la vida. Ahora puedo llevar el control de mis trámites sin complicaciones y desde la comodidad de mi hogar. ¡Es una plataforma confiable y muy fácil de usar!',
    },
    {
      img: '../../../../assets/images/testimony/testi3.jpg',
      titl: 'Diego Cortes',
      subtitle:
        'La experiencia con Pitzon ha sido excelente. Su sistema es claro y eficiente, y me ahorró mucho tiempo en comparación con los procesos tradicionales. ¡Muy recomendado!',
    },
    {
      img: '../../../../assets/images/testimony/testi4.jpg',
      titl: 'Yulian Basto',
      subtitle:
        'Gracias a Pitzon pude solucionar todo lo relacionado con mi vehículo sin estrés. La interfaz es intuitiva y el soporte siempre está disponible para ayudar. ¡Un servicio de primera!',
    },
    {
      img: 'https://plus.unsplash.com/premium_photo-1689568158814-3b8e9c1a9618?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8JTIzcGVyc29uYXxlbnwwfHwwfHx8MA%3D%3D',
      titl: 'Andres Gomez',
      subtitle:
        'Gracias a Pitzon pude solucionar todo lo relacionado con mi vehículo sin estrés. La interfaz es intuitiva y el soporte siempre está disponible para ayudar. ¡Un servicio de primera!',
    },
    {
      img: '../../../../assets/images/testimony/testi2.jpg',
      titl: 'Juan Esteban Bueno',
      subtitle:
        'Gracias a Pitzon pude solucionar todo lo relacionado con mi vehículo sin estrés. La interfaz es intuitiva y el soporte siempre está disponible para ayudar. ¡Un servicio de primera!',
    },
    {
      img: 'https://scontent.feoh10-1.fna.fbcdn.net/v/t39.30808-6/467176196_10228129002313471_8146903825067618678_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=86c6b0&_nc_eui2=AeEJhqGEdsJeeB27h2l4AkJg9TLUmARlKBX1MtSYBGUoFbqHsdIAZrWRJLEUneCJQ8CFrHAbAhM5jxjFdOjVtENN&_nc_ohc=_H7BMY6NlNsQ7kNvgHpcFa_&_nc_zt=23&_nc_ht=scontent.feoh10-1.fna&_nc_gid=A3cvzCdsPK8eWcNHFWE-uWs&oh=00_AYC5DQcvCYdotBG68WbE6juPVfqrImzwyeML8jGKY4fguA&oe=67612B0B',
      titl: 'Angelica Daniela',
      subtitle:
        'Gracias a Pitzon pude solucionar todo lo relacionado con mi vehículo sin estrés. La interfaz es intuitiva y el soporte siempre está disponible para ayudar. ¡Un servicio de primera!',
    },
  ];

  pauseMarquee(): void {
    this.isPaused = true; // Pausa la animación
  }

  resumeMarquee(): void {
    this.isPaused = false; // Reanuda la animación
  }
}
