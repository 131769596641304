<div class="flex flex-wrap h-full sm:h-full md:h-full lg:h-screen xl:h-screen">
  <div *ngIf="isMapVisible" class="absolute bottom-5 left-0 z-50">
<button (click)="toggleMap()" class="bg-primary block sm:inline-block font-bold text-white px-4 py-2 rounded  animate__animated animate__fadeInTopRight animated__delay-1s">
  Volver
</button>
</div>

<div class="flex flex-wrap h-full w-full sm:h-full md:h-full lg:h-screen xl:h-screen">

  <!-- Home Content -->
  <div *ngIf="!isMapVisible || !isSmallScreen" class="w-full md:w-6/12 relative flex flex-col items-center justify-center p-4">
    <!-- <h1 class="text-6xl text-primary font-extrabold primary-color animate__animated animate__zoomInUp animate__delay-1s text-center whitespace-nowrap">
      PITZ<span><img src="../../assets/images/logop.png" class="w-12 inline" alt=""></span>N.<span class="text-primary dark:text-white font-bold">IO</span>
    </h1>     -->
     <h1 class="text-6xl text-primary font-extrabold primary-color animate__animated animate__zoomInUp animate__delay-1s text-center whitespace-nowrap">
      <!-- Imagen para el modo claro -->
      <img *ngIf="isDarkMode === false"
           src="../../assets/images/logo-22.png"
           class="w-72 inline"
           alt="Logo oscuro" />
      <!-- Imagen para el modo oscuro -->
      <img *ngIf="isDarkMode === true"
           src="../../assets/images/logo-23.png"
           class="w-72 inline"
           alt="Logo claro" />
    </h1>
    
    <p class="text-xl mt-2 pt-5 pb-2 animate__animated animate__bounce font-bold text-center 
    text-black dark:text-white">
  {{ 'HOME.SLOGAN' | translate }}
</p>
<p class="text-xl mt-6 animate__animated animate__bounce font-bold text-center 
    text-black dark:text-white">
  {{ 'HOME.DESCRIPTION' | translate }}
</p>

    <!-- Botón traducido -->
    <div class="mt-6">
      <button (click)="goToAssistant()" class="mt-6 bg-primary text-black text-xl font-bold py-2 px-3 rounded-full animate-pulse">
        {{ 'HOME.BUTTON' | translate }}
      </button>
    </div>

    <!-- Servicios -->
    <div class="mt-6 block animate__animated animate__zoomInDown animated__delay-2s text-center">
      <span class="bg-dark p-1 rounded-full text-white text-xs m-1 inline-block cursor-pointer" *ngFor="let ser of services"
      (click)="chatAssistant(ser)">
        {{ser}}
      </span>
    </div>

    <div class="mt-4 pb-16 text-center animate__animated animate__jackInTheBox animate__delay-1s">
      <p>
        {{ 'HOME.ACCEPTANCE_TEXT' | translate }}
        <a href="#" class="text-black dark:text-white">{{ 'HOME.TERMS_CONDITIONS' | translate }}</a>
        {{ 'HOME.AND' | translate }}
        <a href="#" class="text-black dark:text-white">{{ 'HOME.PRIVACY_POLICY' | translate }}</a>.
      </p>  
    </div>
    
    
    <!-- SWITCH -->
     <div *ngIf=" isSmallScreen" class="switchBtnMap">
      <label class="w-16 h-6 relative animate__animated animate__zoomInDown animate__delay-1s animate-pulse">
      <p class="absolute font-bold top-1/2 left-1/2 pb-10 animate-pulse transform -translate-x-1/2 -translate-y-1/2 text-[10px] text-black dark:text-white">MAPA</p>
        <input type="checkbox" alt="Map" placeholder="Mapa" class="custom_switch absolute w-full h-full opacity-0 z-10 cursor-pointer peer" id="custom_switch_checkbox6" (change)="toggleMap()" [checked]="isMapVisible" />
        <span for="custom_switch_checkbox6" class="outline_checkbox border-2 border-primary dark:border-primary block h-full rounded-full before:absolute before:left-1 before:bg-primary dark:before:bg-primary before:bottom-1 before:w-4 before:h-4 before:rounded-full peer-checked:before:left-7 peer-checked:border-primary peer-checked:before:bg-primary before:transition-all before:duration-300"></span>
      </label>
       <!-- SWITCH -->
      </div>
    </div>
    

  <!-- Map -->
  <div *ngIf="isMapVisible || !isSmallScreen"
  class="h-full w-full md:w-6/12 relative animate__animated animate__fadeInTopRight animated__delay-1s"
  [ngClass]="{ 'h-screen': isSmallScreen, 'md:w-6/12': !isSmallScreen }">
  <app-map [cordenadas]="cordenadas" (assistantSchedule)="($event)"></app-map>
</div>
<!-- Map -->
<!-- <div *ngIf="!isMapVisible" class="w-full">
  <img *ngIf="isDarkMode === false" src="../../../assets/images/publicidad.gif" alt="" class="w-full ">
  <img *ngIf="isDarkMode === true" src="../../../assets/images/publicidad2.gif" alt="" class="w-full ">
  <div class="grid grid-cols-2 bg-center justify-items-center ">
    <img src="../../../assets/images/google-play-badge.svg" alt="" class="w-1/2 cursor-pointer animate__animated animate__lightSpeedInRight animate__delay-1s">
    <img src="../../../assets/images/app-store-badge.svg" alt="" class="w-1/2 cursor-pointer animate__animated animate__lightSpeedInLeft animate__delay-1s">
  </div>
</div> -->

<!-- <div *ngIf="!isMapVisible" class="w-full">
  <h2 class="text-3xl font-bold text-black text-center flex items-center justify-center dark:text-white animate__animated animate__zoomInDown animate__delay-1s">Testimonios</h2>
</div>
<div *ngIf="!isMapVisible">
  <app-testimony></app-testimony>
</div> -->


<div class="flex items-center justify-center m-auto">
    <p class="copy text-center text-black pl-10 dark:text-white animate__animated animate__backInDown animate__delay-1s">
      &copy; 2024 PITZON. {{ 'HOME.FOOTER' | translate }}
    </p>
  </div>
  <!-- <footer></footer> -->
</div>
