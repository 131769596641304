import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrl: './edit-profile.component.css',
})
export class EditProfileComponent {
  activeTab = 'home';
  isDarkMode = false;
  store: any;

  constructor(
    private router: Router,
    private storeData: Store<any>,
  ) {
    {
    }
    localStorage.setItem('light', 'dark');
    this.storeData
      .select((state) => state.index.theme)
      .subscribe((theme) => {
        this.isDarkMode = theme === 'dark';
      });
  }
  goBack() {
    this.router.navigate(['/profile']);
  }
}
