<div class="z-40 shadow-sm" [ngClass]="{ 'dark text-white-dark': store.semidark && store.menu === 'horizontal'}">
    <div class="relative flex w-full items-center bg-white px-5 py-2.5 dark:bg-[#0e1726]">
        <div class="horizontal-logo flex items-center justify-between ltr:mr-2 rtl:ml-2 lg:hidden">
            <a routerLink="/" class="main-logo flex shrink-0 items-center">
                <img class="inline w-8 ltr:-ml-1 rtl:-mr-1" src="../../assets/images/logop.png" alt="" />
                <!-- <span class="hidden align-middle text-2xl font-bold transition-all duration-300 ltr:ml-1.5 rtl:mr-1.5 dark:text-primary md:inline animate-pulse">
                    PITZON.<span class="text-white">IO</span>
                </span> -->
            </a>

            <a
                href="javascript:;"
                class="collapse-icon flex flex-none rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary ltr:ml-2 rtl:mr-2 dark:bg-dark/40 dark:text-[#d0d2d6] dark:hover:bg-dark/60 dark:hover:text-primary lg:hidden"
                (click)="storeData.dispatch({type: 'toggleSidebar'})"
            >
                <icon-menu class="h-5 w-5" />
            </a>
        </div>
        <div class="hidden ltr:mr-2 rtl:ml-2 sm:block">
            <ul class="flex items-center space-x-2 rtl:space-x-reverse dark:text-[#d0d2d6]">

            </ul>
        </div>
        <div
            class="flex items-center space-x-1.5 ltr:ml-auto rtl:mr-auto rtl:space-x-reverse dark:text-[#d0d2d6] sm:flex-1 ltr:sm:ml-0 sm:rtl:mr-0 lg:space-x-2"
        >
            <div class="sm:ltr:mr-auto sm:rtl:ml-auto">
                
            </div>
            <div>
                <!-- Si el tema es 'light' -->
                <!-- <a
                    href="javascript:;"
                    *ngIf="store.theme === 'light'"
                    class="flex items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60"
                    (click)="storeData.dispatch({ type: 'toggleTheme', payload: 'dark' })"
                >
                    <icon-sun />
                </a> -->
            
                <!-- Si el tema es 'dark' -->
                <a
                href="javascript:;"
                *ngIf="store.theme === 'dark'"
                class="flex items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60"
                (click)="storeData.dispatch({ type: 'toggleTheme', payload: 'system' })"
            >
                <icon-sun />
            </a>
        
            <!-- Si el tema es 'system' -->
            <a
                href="javascript:;"
                *ngIf="store.theme === 'system'"
                class="flex items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60"
                (click)="storeData.dispatch({ type: 'toggleTheme', payload: 'dark' })"
            >
                <icon-moon />
            </a>
            </div>
            

            <div class="dropdown shrink-0">
                <div hlMenu>
                    <button
                        type="button"
                        hlMenuButton
                        class="block rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60"
                        >
                        <img 
                            [src]="store.locale === 'es' ? '/assets/images/flags/CO.svg' : '/assets/images/flags/' + store.locale.toUpperCase() + '.svg'" 
                            alt="flag" 
                            class="h-5 w-5 rounded-full object-cover" 
                        />
                    </button>

                    <ul
                        *hlMenuItems
                        @toggleAnimation
                        class="top-11 grid w-[280px] grid-cols-2 gap-2 !px-2 font-semibold text-dark ltr:-right-14 rtl:-left-14 dark:text-white-dark dark:text-white-light/90 sm:ltr:-right-2 sm:rtl:-left-2"
                    >
                        <li *ngFor="let item of store.languageList;">
                            <button
                                *hlMenuItem="let menuItem"
                                type="button"
                                class="w-full hover:text-primary"
                                [ngClass]="{ 'bg-primary/10 text-primary': translate.currentLang === item.code }"
                                (click)="changeLanguage(item)"
                                >
                                <img 
                                    class="h-5 w-5 rounded-full object-cover" 
                                    [src]="item.code === 'es' ? '/assets/images/flags/CO.svg' : '/assets/images/flags/' + item.code.toUpperCase() + '.svg'" 
                                    alt="" 
                                />
                                <span class="ltr:ml-3 rtl:mr-3">{{ item.name }}</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- <div class="dropdown shrink-0">
                <div class="w-10 h-10 relative">
                    <img 
                        (click)="toggleDropdown()" 
                        class="w-10 h-10 rounded-full cursor-pointer overflow-hidden object-cover" 
                        src="../../assets/images/juan2.png" 
                        alt="image" 
                    />
                    <span class="absolute ltr:right-0 rtl:left-0 bottom-0 w-2 h-2 rounded-full ring-2 ring-white dark:ring-white-dark bg-success"></span>
                
                    Menú desplegable
                    <div *ngIf="isDropdownOpen" class="absolute ltr:right-0 rtl:left-0 mt-2 w-48 bg-white dark:bg-gray-800 shadow-lg rounded-md z-10">
                        <ul class="py-2 text-sm text-gray-700 dark:text-gray-200">
                            <li>
                                <a (click)="goToProfile()" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer">
                                    Perfil
                                </a>
                            </li>
                            <li>
                                <a (click)="logout()" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer">
                                    Cerrar Sesión
                                </a>
                            </li>
                        </ul>
                    </div>
                <div hlMenu>
                </div>
            </div> -->

            <div class="dropdown shrink-0">
                
              
            </div>

            <div class="dropdown shrink-0">
                
            </div>
        </div>
    </div>

</div>
