<div 
  class="testimony-container"
  (mouseenter)="pauseMarquee()"
  (mouseleave)="resumeMarquee()"
>
  <div class="testimony-marquee flex gap-5 animate-marquee" [class.paused]="isPaused">
    <div *ngFor="let testimony of testimony" class="card shadow-md rounded-lg p-4 w-80 flex flex-col items-center">
      <img src="{{testimony.img}}" alt="User image" class="w-36 h-36 rounded-full object-cover mb-4">
      <div class="card__content text-center">
        <p class="card__title text-lg font-bold mb-2">{{testimony.titl}}</p>
        <p class="card__description text-white">{{testimony.subtitle}}</p>
      </div>
    </div>
  </div>
</div>
