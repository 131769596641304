    <div class="mb-5 grid grid-cols-1 gap-5 lg:grid-cols-2 animate__animated animate__fadeInUp">
        <div *ngFor="let documentation of documentation" class="panel">
            <div class="mb-5 text-black dark:text-white">
                <h5 class="mb-4 text-lg font-semibold">{{documentation.name}}</h5>
                <p>
                    {{documentation.description}}
                </p>
            </div>
            <div class="mb-5">
                <div class="border-b border-[#ebedf2] dark:border-[#1b2e4b]">
                    <div class="flex items-start justify-between py-3">
                        <h6 class="text-[15px] font-bold text-[#515365] dark:text-white-dark">
                            ENTIDAD 
                            <span class="mt-1 block text-xs font-normal text-white-dark dark:text-white-light">
                                {{documentation.entidad}}
                                <span class="italic mt-1 block text-xs font-normal text-green-500">{{ documentation.date }}</span>
                            </span>
                        </h6>
                        <div class="flex items-start justify-between ltr:ml-auto rtl:mr-auto">
                            <button class="btn border-white bg-green-500 cursor-not-allowed"> {{ documentation.statusActive}} </button>
                        </div>
                    </div>
                </div>
                <div class="border-b border-[#ebedf2] dark:border-[#1b2e4b]">
                    <div class="flex items-start justify-between py-3">
                        <h6 class="text-[15px] font-bold text-[#515365] dark:text-white-dark">
                            ENTIDAD
                            <span class="mt-1 block text-xs font-normal text-white-dark dark:text-white-light">
                                SEGUROS S.A.S 
                                <span class="italic mt-1 block text-xs font-normal text-red-500">{{ documentation.date }}</span>
                            </span>
                        </h6>
                        <div class="flex items-start justify-between ltr:ml-auto rtl:mr-auto">
                            <button class="btn border-white bg-red-500 cursor-not-allowed">{{ documentation.statusDesative }}</button>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="flex items-start justify-between py-3">
                        <h6 class="text-[15px] font-bold text-[#515365] dark:text-white-dark">
                            ENTIDAD
                            <span class="mt-1 block text-xs font-normal text-white-dark dark:text-white-light">
                                SEGUROS S.A.S 
                                <span class="italic mt-1 block text-xs font-normal text-red-500">{{ documentation.date }}</span>
                            </span>
                        </h6>
                        <div class="flex items-start justify-between ltr:ml-auto rtl:mr-auto">
                            <button class="btn border-white bg-red-500 cursor-not-allowed">{{ documentation.statusDesative }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <button class="btn btn-primary">Pagar aquí</button>
        </div>
    </div>
