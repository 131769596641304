<div>
    <header></header>
    <ul class="flex space-x-2 rtl:space-x-reverse">
        <li>
            <a (click)="goBack()" class="text-black dark:text-white cursor-pointer hover:underline animate__animated animate__bounceInLeft animate__delay-1s">Perfil</a>
        </li>
        <li class="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-black dark:text-white">
            <span class="text-primary animate__animated animate__bounceInRight animate__delay-1s">Editar Perfil</span>
        </li>
    </ul>
    <div class="bg-center flex items-center justify-center animate__animated animate__fadeIn animate__delay-1s">
    <img *ngIf="isDarkMode === false" src="../../../../assets/images/logo-22.png" alt="img" class=" w-36 flex justify-center items-center animate__animated animate__fadeIn animate__delay-1s">
    <img *ngIf="isDarkMode === true" src="../../../../assets/images/logo-23.png" alt="img" class=" w-36 flex justify-center items-center animate__animated animate__fadeIn animate__delay-1s">
    </div>
    <div class="pt-2">
        <div class="mb-5 flex items-center justify-between">
            <h5 class="text-lg font-semibold  text-black dark:text-white animat__animated animate__bounceIn animate__delay-1s">Configuraciones</h5>
        </div>
        <!-- Navs -->
        <div class="mb-5 flex overflow-y-auto whitespace-nowrap border-b text-black dark:text-white border-primary font-semibold animate__animated animate__slideInRight animate__delay-1s">
            <a
                href="javascript:;"
                class="flex gap-2 border-b border-transparent p-4 !outline-none hover:border-primary hover:text-primary"
                [ngClass]="{ '!border-primary text-primary':  activeTab.toLowerCase() === 'home'}"
                (click)="activeTab = 'home'"
            >
                <icon-home />
                inicio
            </a>
            <a
                href="javascript:;"
                class="flex gap-2 border-b border-transparent p-4 !outline-none hover:border-primary hover:text-primary"
                [ngClass]="{ '!border-primary text-primary':  activeTab.toLowerCase() === 'payment-detail'}"
                (click)="activeTab = 'payment-detail'"
            >
                <icon-dollar-sign-circle />
                Documentación
            </a>
            <a
                href="javascript:;"
                class="flex gap-2 border-b border-transparent p-4 !outline-none hover:border-primary hover:text-primary"
                [ngClass]="{ '!border-primary text-primary':  activeTab.toLowerCase() === 'preferences'}"
                (click)="activeTab = 'preferences'"
            >
                <icon-user class="h-5 w-5" />
                Mi vehículo
            </a>
            <!-- <a
                href="javascript:;"
                class="flex gap-2 border-b border-transparent p-4 !outline-none hover:border-primary hover:text-primary"
                [ngClass]="{ '!border-primary text-primary':  activeTab.toLowerCase() === 'danger-zone'}"
                (click)="activeTab = 'danger-zone'"
            >
                <icon-phone />
                Danger Zone
            </a> -->
        </div>
        <!-- End Navs -->
        <div>
            <div *ngIf="activeTab.toLowerCase() === 'home'">
                <app-home-profile></app-home-profile>
            </div>
            <!-- DOCUMENTATION -->
            <div *ngIf="activeTab.toLowerCase() === 'payment-detail'" class="switch text-black dark:text-white">
                <app-documentation></app-documentation>
              </div>
              
            <!-- VEHICLE -->
            <div *ngIf="activeTab.toLowerCase() === 'preferences'" class="switch text-black dark:text-white">
                <app-vehicle></app-vehicle>
            </div>

            <!-- OTHER -->
            <div *ngIf="activeTab.toLowerCase() === 'danger-zone'" class="switch">
                <app-other></app-other>
            </div>
        </div>
    </div>
</div>
