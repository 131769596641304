<div class="dropdown ms-auto w-max">
    <div hlMenu>
        <button
            type="button"
            hlMenuButton
            class="flex items-center gap-2.5 rounded-lg border border-white-dark/30 bg-white px-2 py-1.5 text-white-dark hover:border-primary hover:text-primary dark:bg-black"
        >
            <div>
                <img
                [src]="store.locale === 'es' ? '/assets/images/flags/CO.svg' : '/assets/images/flags/' + store.locale.toUpperCase() + '.svg'"
                alt="image"
                class="h-5 w-5 rounded-full object-cover"
                />
            </div>
            <div class="text-base font-bold uppercase">{{ store.locale }}</div>
            <span class="shrink-0">
                <icon-caret-down />
            </span>
        </button>
        <ul
            *hlMenuItems
            @toggleAnimation
            class="top-11 grid w-[280px] grid-cols-2 gap-2 !px-2 font-semibold text-dark ltr:-right-14 rtl:-left-14 dark:text-white-dark dark:text-white-light/90 sm:ltr:-right-2 sm:rtl:-left-2"
        >
            <li *ngFor="let item of store.languageList;">
                <button
                    *hlMenuItem="let menuItem"
                    type="button"
                    class="w-full hover:text-primary"
                    [ngClass]="{ 'bg-primary/10 text-primary': translate.currentLang === item.code }"
                    (click)="changeLanguage(item)"
                >
                    <img
                    class="h-5 w-5 rounded-full object-cover"
                    [src]="item.code === 'es' ? '/assets/images/flags/CO.svg' : '/assets/images/flags/' + item.code.toUpperCase() + '.svg'"
                    alt=""
                    />
                    <span class="ltr:ml-3 rtl:mr-3">{{ item.name }}</span>
                </button>
            </li>
        </ul>
    </div>
</div>