export const environment = {
  production: false,
  apiUrl: 'https://api.pitzon.io/api-core/v1/',
  socketUrl: 'https://api.pitzon.io',
  secrets: {
    key: 'o4pSi0Ko1SHToD9geVmSTC5bdqQZIg6XuFjILdeBEEw=', // Debe ser exactamente 32 caracteres
    ivLength: 16, // Longitud del IV en bytes
    appCode: '9b83dc5f-e7c2-4616-8766-792f7d662096',
  },
  firabase: {
    projectId: 'pitzon-project',
    appId: '1:163751592831:web:3d21a657f7e30b5e28904a',
    storageBucket: 'pitzon-project.firebasestorage.app',
    apiKey: 'AIzaSyDKOAeXifUWDzC0XkDbH777-qxPfc4V5wo',
    authDomain: 'pitzon-project.firebaseapp.com',
    messagingSenderId: '163751592831',
    measurementId: 'G-31MBJL3BVJ',
  },
};
