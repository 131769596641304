import { Component } from '@angular/core';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrl: './vehicle.component.css',
})
export class VehicleComponent {
  vehicle: any[] = [
    { name: 'Chevrolet Spark', km: '10.000' },
    { name: 'Ducatti', km: '17.000' },
    { name: 'BMW', km: '20.000' },
  ];
  plate: any[] = [{ day: 'Martes' }, { day: 'Jueves' }, { day: 'Viernes' }];
}
