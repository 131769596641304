import { Component } from '@angular/core';
import { AnalitycsService } from 'src/app/shared/service/analitycs.service';
import moment from 'moment';

@Component({
  selector: 'app-networks',
  templateUrl: './networks.component.html',
  styleUrl: './networks.component.css',
})
export class NetworksComponent {
  constructor(private analitycs: AnalitycsService) {
    // Initialize the Firebase Analytics SDK.
    // this.analytics = getAnalytics();
  }

  networks() {
    this.analitycs.logEvent('networks', {
      view: 'login',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
  }
}
