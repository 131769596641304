<form class="mb-5 rounded-md border border-[#ebf2ee] bg-white p-4 dark:border-white  text-black dark:text-white dark:bg-[#0e1726] animate__animated animate__zoomInUp">
    <h6 class="mb-5 text-lg font-bold">Información Personal</h6>
    <div class="flex flex-col sm:flex-row">
        <div class="mb-5 w-full sm:w-2/12 ltr:sm:mr-4 rtl:sm:ml-4">
            <img src="../../../../assets/images/juan1.png" alt="" class="mx-auto h-40 w-40 rounded-full object-cover md:h-32 md:w-32" />
        </div>
        <div class="grid flex-1 grid-cols-1 gap-5 sm:grid-cols-2">
            <div>
                <label for="name">Nombre</label>
                <input id="name" type="text" placeholder="Juan" class="form-input" />
            </div>
            <div>
                <label for="profession">Apellido</label>
                <input id="profession" type="text" placeholder="Mecanico" class="form-input" />
            </div>
            <div>
                <label for="country">Pais</label>
                <select id="country" class="form-select text-white-dark">
                    <option selected>Colombia</option>
                    <option *ngFor="let option of options">{{ option }}</option> 
                </select>
            </div>
            <div>
                <label for="address">Ciudad</label>
                <input id="address" type="text" placeholder="Pereira" class="form-input" />
            </div>
            <div>
                <label for="location">Correo</label>
                <input id="location" type="text" placeholder="Juan@gmail.com" class="form-input" />
            </div>
            <div>
                <label for="phone">Telefono</label>
                <input id="phone" type="number" placeholder="(57) 300-3201543" class="form-input" />
            </div>
            <div>
                <label for="email">Contraseña</label>
                <input id="email" type="email" placeholder="********" class="form-input" />
            </div>
            <div>
                <label for="web">Repetir contraseña</label>
                <input id="web" type="text" placeholder="********" class="form-input" />
            </div>
            <div>
            </div>
            <div class="mt-3 sm:col-span-2">
                <button type="button" class="btn btn-primary">Guardar Cambios</button>
            </div>
        </div>
    </div>
</form>
<form class="rounded-md border border-[#ebedf2] bg-white p-4 dark:border-[#191e3a] dark:bg-[#0e1726]">
    <h6 class="mb-5 text-lg font-bold  text-black dark:text-white">Redes</h6>
    <div class="grid grid-cols-1 gap-5 sm:grid-cols-2">
        <div class="flex">
            <div class="flex items-center justify-center rounded bg-[#eee] px-3 font-semibold dark:bg-[#1b2e4b] ltr:mr-2 rtl:ml-2">
                <icon-instagram class="h-5 w-5 text-primary" />
            </div>
            <input type="text" placeholder="Juan_mecanico" class="form-input" />
        </div>
        <div class="flex">
            <div class="flex items-center justify-center rounded bg-[#eee] px-3 font-semibold dark:bg-[#1b2e4b] ltr:mr-2 rtl:ml-2">
                <icon-twitter class="h-5 w-5 text-primary" />
            </div>
            <input type="text" placeholder="Juan_mecanico" class="form-input" />
        </div>
        <div class="flex">
            <div class="flex items-center justify-center rounded bg-[#eee] px-3 font-semibold dark:bg-[#1b2e4b] ltr:mr-2 rtl:ml-2">
                <icon-facebook class="h-5 w-5 text-primary"/>
            </div>
            <input type="text" placeholder="Juan_mecanico" class="form-input" />
        </div>
        <div class="flex">
            <div class="flex items-center justify-center rounded bg-[#eee] px-3 font-semibold dark:bg-[#1b2e4b] ltr:mr-2 rtl:ml-2">
                <icon-linkedin class="h-5 w-5 text-primary" />
            </div>
            <input type="text" placeholder="Juan_mecanico" class="form-input" />
        </div>
    </div>
</form>