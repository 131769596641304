<div class="mb-5 lg:grid-cols-2 animate__animated animate__fadeInDown">
  <div class="panel space-y-5">
    <h5 class="mb-4 text-lg font-semibold">Mis vehículos</h5>
    <div class="flex justify-around">
      <label class="inline-flex cursor-pointer">
        <!-- <input class="form-radio cursor-pointer ltr:mr-4 rtl:ml-4" type="radio" name="flexRadioDefault" checked /> -->
        <span>
          <img
            class="ms-3"
            width="200"
            height="68"
            alt="settings-dark"
            src="../../../../../../assets/images/car1.png"
          />
        </span>
      </label>

      <label class="inline-flex cursor-pointer">
        <!-- <input class="form-radio cursor-pointer ltr:mr-4 rtl:ml-4" type="radio" name="flexRadioDefault" /> -->
        <span>
          <img
            class="ms-3"
            width="200"
            height="68"
            alt="settings-light"
            src="https://images.ctfassets.net/x7j9qwvpvr5s/3nNxSK1xQLESBdlyU5Eur0/89de1e6ada14da2f854efeed352b6ca7/Ducati-Unica-Model-Preview-1050x650.png"
          />
        </span>
      </label>
      <label class="inline-flex cursor-pointer">
        <!-- <input class="form-radio cursor-pointer ltr:mr-4 rtl:ml-4" type="radio" name="flexRadioDefault" /> -->
        <span>
            <img class="ms-3" 
            width="200"
            height="68"
            alt="settings-light" 
            src="../../../../../../assets/images/car2.png"/>
         </span>
      </label>
    </div>
  </div>
  <!-- <div class="panel space-y-5">
        <h5 class="mb-4 text-lg font-semibold">Activity data</h5>
        <p>Download your Summary, Task and Payment History Data</p>
        <button type="button" class="btn btn-primary">Download Data</button>
    </div>
</div> -->
  <div class="grid grid-cols-3 gap-5 md:grid-cols-3 pt-5 animate__animated animate__fadeInUp animate__delay-1s">
    <div *ngFor="let vehicle of vehicle" class="panel space-y-5 grid grid-cols-3">
      <h5 class="mb-4 text-lg font-semibold">{{ vehicle.name }}</h5>
      <p>
        Kilometraje: <span class="text-primary">{{ vehicle.km }}</span
        >KM
      </p>
      <!-- <button type="button" class="btn btn-primary">Actualizar KM</button> -->
    </div>
    <div *ngFor="let plate of plate" class="panel space-y-5 animate__animated animate__fadeInUp animate__delay-1s">
      <h5 class="mb-4 text-lg font-semibold">Pico y placa</h5>
      <p>
        Dia: <span class="text-primary">{{ plate.day }}</span>
      </p>
      <label class="relative h-6 w-12">
        <input type="checkbox" class="custom_switch peer absolute z-10 h-full w-full cursor-pointer opacity-0" id="custom_switch_checkbox5" />
        <span
          for="custom_switch_checkbox5"
          class="block h-full rounded-full bg-[#ebedf2] before:absolute before:bottom-1 before:left-1 before:h-4 before:w-4 before:rounded-full before:bg-white before:transition-all before:duration-300 peer-checked:bg-primary peer-checked:before:left-7 dark:bg-dark dark:before:bg-white-dark dark:peer-checked:before:bg-white"
        ></span>
      </label>
    </div>
  </div>
