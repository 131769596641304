import { Injectable } from '@angular/core';
import { Analytics, logEvent } from '@angular/fire/analytics';

@Injectable({
  providedIn: 'root',
})
export class AnalitycsService {
  constructor(private analytics: Analytics) {
    // Initialize the Firebase Analytics SDK.
    // this.analytics = getAnalytics();
  }

  // Log an event with the Firebase Analytics SDK.
  logEvent(eventName: string, eventParams?: { [key: string]: any }) {
    logEvent(this.analytics, eventName, eventParams);
  }
}
