import { Component } from '@angular/core';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrl: './documentation.component.css',
})
export class DocumentationComponent {
  documentation: any[] = [
    {
      name: 'SOAT',
      subtitle: 'Garantiza atenciónmédica inmediata y sin costo para las víctimas de un accidente.',
      entidad: 'SEGUROS S.A.S',
      date: '10/102020 - 09/102021',
      statusDesative: 'NO VIGENTE',
      statusActive: 'VIGENTE',
    },
    {
      name: 'TÉCNICO-MECÁNICA',
      subtitle: 'Asegura que el vehículo esté en buen estado mecánico y cumple con las normas de seguridad vial.',
      entidad: 'SEGUROS S.A.S',
      date: '10/102020 - 09/102021',
      statusDesative: 'NO VIGENTE',
      statusActive: 'VIGENTE',
    },
    {
      name: 'POLIZA DE SEGUROS',
      subtitle: 'Proporciona tranquilidad financiera al cubrir costos elevados en caso de siniestros.',
      entidad: 'SEGUROS S.A.S',
      date: '10/102020 - 09/102021',
      statusDesative: 'NO VIGENTE',
      statusActive: 'VIGENTE',
    },
    {
      name: 'CERTIFICACIÓN AMBIENTAL',
      subtitle: 'Garantiza que el vehículo cumple con las normativas de emisiones contaminantes, reduciendo el impacto ambiental.',
      entidad: 'SEGUROS S.A.S',
      date: '10/102020 - 09/102021',
      statusDesative: 'NO VIGENTE',
      statusActive: 'VIGENTE',
    },
  ];
}
